<template>
    <div class="transparent-background">
      <div v-if="showPaypal" class="clickblock"  style="background-color: rgba(255, 255, 255, 0.9);"> 
        <div  style="position:absolute; top: 5%; width: 100%; text-align: center;  text-shadow: 15px;">
          <div class="mt-4 float-end" @click="(showPaypal=false)">
            <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
              <font-awesome-icon class="fa-2xl" icon="fa-solid fa-close" style="margin-right: 20px;"/>
            </button>
          </div>
        <h6>{{buy_item}} - ${{buy_price}}</h6>
        </div>
        <div id="paypal-button-container" class="centered-div" style="margin-top: 50px; width: 50%;"></div>
      </div>
      <div class="centered-div">
        <div class="container">
          <div class="hover" :key="item[0]"  v-for="(item) in availableItems" @click="OnPayment(item.ID, item.Name+'. '+item.Description,item.Price)">
            
            <default-info-card style="background-color:rgba(255, 255, 255, 0.8)!important; min-width: 200px;"
              icon="fa-solid fa-hourglass-half"
              :title="`${item.Name}`"
              :description="`${item.Description}`"
              :value="`${isTW}` == 'true' ?`${item.PriceNT}`:`${item.Price}`"
              :currency="`${isTW}` == 'true' ?' NT':' USD'"
            />
          </div> 
        </div>
        <br>
        <div class="d-flex">
          <input type="string" v-model="coupon_code" class="px-3 mb-2 form-control w-100 ms-2" placeholder="Coupon Code" >
        
          <soft-button color="info" variant="gradient" class="px-3 mb-2 btn  w-100 ms-2" @click="OnUseCode">
              Use Coupon Code
            </soft-button>
        </div>
      </div>
      

  
      </div>
</template>

<script>
import DefaultInfoCard from "@/components/DefaultInfoCard.vue";
import SoftButton from "@/components/SoftButton.vue";
// import SoftInput from "@/components/SoftInput.vue";
import { loadScript } from "@paypal/paypal-js";
import { API } from 'aws-amplify';
export default {
  name: "addon",
  props: ['feature_id'],
  components: {
    DefaultInfoCard,
    SoftButton,
    // SoftInput,
  },
  data()
  {
    return{
      storeInventory: [],
      item: null,
      loading: false,
      isTW: false,
      showPaypal: false,
      coupon_code: ''
    }
  },
  async mounted() {
    this.loading = true;
    this.$store.state.showSidenav = false;
    this.$store.state.showNavs = false;
    this.$store.state.showSlidesPanel = false;
    this.$store.state.showCameraPanel = false;
    this.$store.state.showConfig = false
    this.$store.state.showCharacterPanel = false;
    this.$store.state.showEnvironmentPanel = false;
    this.$store.state.showEditorPanel = false;
    this.$store.state.showRecordingPanel = false;
   

    const inventory = await API.get('rpgStoreInventory','/items');
    this.storeInventory = inventory.items;
    this.isTW = inventory.country==="TW";
    this.loading = false;
  },
  computed: {
        availableItems() {
            // Return only the items that are available
            return this.storeInventory.filter(item => (item.Available&&item.Type=="License"));
        }
    },
  methods:{
    async OnUseCode()
    {
      this.loading = true;
        let el = this.coupon_code;
        //console.log(el);
        let params = {
            'queryStringParameters':
            {
                code: el
            }
        }
        await API.get('rpgPromocode','/use',params);
        //console.log(data2);
        //await this.profile();
        this.coupon_code ="";
        this.loading = false;
        this.$router.replace('/payment?Status=SUCCESS&orderResult=Activated with promo code'); 
    },
    async OnPayment(id, item, price)
    {
      
      if(this.isTW)
      {
        this.OnNewebpay(id, item, price);
      }
      else
      {
        this.OnPaypal(id, item, price);
      }
      
    },
    async OnPaypal(id, item, price)
    {
      this.buy_item = item;
      this.buy_price = price;
      this.showPaypal = true;
      try {
            //this.paypal = await loadScript({ "client-id": "AaJgEimNyjVWkcKlUA0FNAEo-IOULm5bWzVy7FZXUIp28VpoC4s9go53hhYH-ezfOcvTZlk9OnFBcKzu"});
            
            console.log("the PayPal JS SDK loaded: ", this.paypal);
        } catch (error) {
            console.error("failed to load the PayPal JS SDK script", error);
        }
      await this.mountpaypalbutton(id);
      //create(DefaultInfoCard);
    },
    async mountpaypalbutton(id)
    {
      let app = this;
      //prod
      //this.paypal = await loadScript({ "client-id": "AcrU1NHxDpJpdMbqvpaG8hkTrnjDXDoY1BAM5QaFBhENo4RBeBFrIxOVUktXBi1gXPAuIdXWusWAnI0s"});
      //dev
      if(this.env=='dev' )
        this.paypal = await loadScript({ "client-id": "AaJgEimNyjVWkcKlUA0FNAEo-IOULm5bWzVy7FZXUIp28VpoC4s9go53hhYH-ezfOcvTZlk9OnFBcKzu"});
      else
        this.paypal = await loadScript({ "client-id": "AcrU1NHxDpJpdMbqvpaG8hkTrnjDXDoY1BAM5QaFBhENo4RBeBFrIxOVUktXBi1gXPAuIdXWusWAnI0s"});
      await this.paypal.Buttons({
        style: {
          shape: 'pill',
          color: 'blue',
          layout: 'vertical',
          label: 'paypal',
          
        },

        createOrder: async function() {
          app.loading = true;
          console.log("Paypal createOrder")
          const orderDetails = {    
              headers: { 
                  "Content-Type":"application/json"
              },
              body: {
                'items': [
                  {
                    'id': id,
                    'quantity': 1,
                  }
                ],
              }
          }
          try{
            console.log("Get Paypal order id");
            let data = await API.post('rpgPayment','/payment', orderDetails);
            console.log({data});
            app.loading = false;
            return data.orderID;
          }
          catch(err)
          {
            console.log(err.message);
            app.loading = false;
          }
         
        },

        onApprove:  async function(data) {
            // Full available details
            console.log('Capture result', data, JSON.stringify(data, null, 2));
            try{
              this.$router.replace(`/payment?orderID=`+data.orderID+"&itemID="+id); 
            }catch(err)
            {
              console.log(err.message)
              const element = document.getElementById('paypal-button-container');
              element.innerHTML = '';
              element.innerHTML = '<h3>Something went wrong!</h3>';
            }
     
            // Or go to another URL:  actions.redirect('thank_you.html');
            
          //});
        },

        onError: function(err) {
          console.log(err);
          app.loading = false;
        }
      }).render('#paypal-button-container');
     this.loading = false;
    },
    async OnNewebpay(id, item, price)
    {
      console.log("newebpay "+item+" : "+price);
      const orderDetails = {    
            headers: { 
                "Content-Type":"application/json"
            },
            body: {
              'items': [
                {
                  'id': id,
                  'quantity': 1,
                }
              ],
            }
        }
        this.loading = true;
        let data = await API.post('rpgPayment','/payment',orderDetails);
        console.log({data});
        let form_string ='';
        if(this.env=='dev')
        {
         form_string = `
          <form id="newebpay-container2" method="POST" action="https://ccore.newebpay.com/MPG/mpg_gateway" hidden>
              <input name="MerchantID" value="${data.mid}" readonly>
              <input name="Version" value="2.0" readonly>
              <input name="TradeInfo" value="${data.post_data}" readonly>
              <input name="TradeSha" value="${data.hash}" readonly>
          </form>
          `;
        }
        else
        {
          form_string = `
          <form id="newebpay-container2" method="POST" action="https://core.newebpay.com/MPG/mpg_gateway" hidden>
              <input name="MerchantID" value="${data.mid}" readonly>
              <input name="Version" value="2.0" readonly>
              <input name="TradeInfo" value="${data.post_data}" readonly>
              <input name="TradeSha" value="${data.hash}" readonly>
          </form>
          `;
        }
      document.body.insertAdjacentHTML('beforeend', form_string);
      const form = document.getElementById("newebpay-container2");

      form.submit();
      this.loading = false;
    
    }
  },
  watch: {
		loading: function (loading) {
			if (loading) 
			{
				this.loader = this.$loading.show({container: this.$refs.loadingContainer});
			} else 
			{
				this.loader.hide();
				this.loader = null;
			}
		},
    }
  
}
</script>

<style >
.hover:hover{
  transform: scale(1.02);
}
.transparent-background {
  position:fixed;

  top:0px;
  right:0px;
  bottom:0px;
  left:0px;
  width: 100%;
  height: 100%;
  z-index: -1;  
  background-color: #132C47;
}
.centered-div {
  display: flex; /* Flex display on the centered div */
  flex-direction: column; /* Ensures children are stacked vertically */
  justify-content: center;
  align-items: center;
  height: auto; /* Content height */
  width: auto; /* Content width */
  padding: 20px; /* Optional padding */

}
.container {
    display: flex; /* Enables flexbox layout */
    justify-content: center;   /* Centers items horizontally */
    gap: 30px;
}

.item {
    margin: 0px; /* Optional: Adds space between items */
}

.clickblock{
    display: flex; /* Flex display on the centered div */
    flex-direction: column; /* Ensures children are stacked vertically */
    justify-content: center;
    align-items: center;
    
    width:100%;
    height: 100%;
   
    /* background-color: rgba(94, 94, 94, 0.4); */
    background-color: #132C47;
    z-index: 9;
   
  
}
</style>